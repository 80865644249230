const dataRoadMap = [
  {
    time: "Paso 1",
    title: "Entrevista con el cliente",
    desc: "Conocemos las necesidades del cliente, sus objetivos, la estética de su empresa. Le solicitamos la informacón necesaria que debe mostrarse.",
    class: "left",
  },
  {
    time: "Paso 2",
    title: "Nuestra propuesta",
    desc: "Proponemos un diseño, elegimos los colores y si el cliente lo aprueba, pasamos a producción.",
    class: "right mt-223",
  },
  {
    time: "Paso 3",
    title: "Prototipo",
    desc: "Presentamos un modelo funcional terminado.",
    class: "left mt--23",
  },
  {
    time: "Paso 4",
    title: "Correcciones (Este paso puede repetirse)",
    desc: "Se hacen cambios a pedido del cliente, textos, imágenes, etc.",
    class: "right mt-200",
  },
];

export default dataRoadMap;
